<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>动态</b></span>
            <!--<div class="flex-center"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>-->
        </div>
        <div class="send-info">
            <div class="send-condition flex-center">
                <div class="send-condition-item flex-center">
                    <span>审核类型:</span>
                    <el-select v-model="type" placeholder="请选择" @change="classifyChange($event)">
                        <el-option
                                v-for="item in typeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <!--<el-checkbox v-model="checked" class="checked-chiose"><span>仅未读</span></el-checkbox>-->
                <!--<a href="javascript:void 0">全部标记为已读</a>-->
            </div>
            <div class="send-list">
                <div class="dynamic-list">
                    <div class="dynamic-item active" v-for="(item) in list" :key="item.id" @click="toDetail(item)">
                        <p class="ovHide2"><span>{{item.add_time}}</span> {{item.title}}</p>
                    </div>
                </div>
                <el-pagination
                        class="page-all"
                        background
                        layout="prev, pager, next"
                        :page-size="limit"
                        :total="pageTotal"
                        :current-page.sync="page"
                        @current-change="setPage">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "dynamic",
        data() {
            return {
                typeOptions: this.$tools.approvalClassify(),
                type: '',
                list: [],
                page: 1,
                pageTotal: 1,
                limit: 9,
            }
        },
        created() {
            this.$emit('setIndex', [0, 5])
            this.init()
        },
        methods: {
            classifyChange(event) {
                this.type = event
                this.page = 1
                this.init()
            },
            // 分页变化
            setPage(val) {
                this.page = val
                this.init()
            },
            init() {
                this.$api.apiContent.getDongtai({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    page: this.page,
                    limit: this.limit,
                    type: this.type ? this.type : 0,
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        page: this.page,
                        limit: this.limit,
                        type: this.type ? this.type : 0,
                    }, this.$store.state.user.key)
                }).then(res => {
                    res.data.list.map(item => {
                        item.add_time = this.$tools.ZHDate(new Date(item.add_time * 1000), 1)
                    })
                    this.list = res.data.list
                    this.pageTotal = res.data.count
                }).catch(() => {
                    this.list = []
                    this.pageTotal = 0
                })
            },
            toDetail(row) {
                let type = 1
                //1我发起的 0 我审批的 2 抄送我的
                switch (Number(row.type)) {
                    case 1:
                        this.$router.push({name: 'approval', query: {id: row.oid, type: type}})
                        break
                    case 2:
                        this.$router.push({name: 'merchants', query: {id: row.oid, type: type}})
                        break
                    case 3:
                        this.$router.push({name: 'sponsor', query: {id: row.oid, type: type}})
                        break
                    case  4:
                        this.$router.push({name: 'payment', query: {id: row.oid, type: type}})
                        break
                    case 5:
                        this.$router.push({name: 'invoice', query: {id: row.oid, type: type}})
                        break
                    case 6:
                        this.$router.push({name: 'contract', query: {id: row.oid, type: type}})
                        break
                    case 7:
                        this.$router.push({name: 'otherPay', query: {id: row.oid, type: type}})
                        break
                    case 8:
                        this.$router.push({name: 'fpr', query: {id: row.oid, type: type}})
                        break
                    case 9:
                        this.$router.push({name: 'pay', query: {id: row.oid, type: type}})
                        break
                    case 10:
                        this.$router.push({
                            name: "rate",
                            query: {id: row.oid, type: type, classify: 10},
                        });
                        break;
                    case 11:
                        this.$router.push({
                            name: "rate",
                            query: {id: row.oid, type: type, classify: 11},
                        });
                        break;
                    case 12:
                        this.$router.push({
                            name: "rate",
                            query: {id: row.oid, type: type, classify: 12},
                        });
                        break;
                    case 13:
                        this.$router.push({name: 'otherFprDetail', query: {id: row.oid, type: type}})
                        break;
                    case 14:
                        this.$router.push({
                            name: "otherApprovalDetail",
                            query: {id: row.oid, type: type},
                        });
                        break;
                    case 15:
                        this.$router.push({
                            name: "advanceDetail",
                            query: {id: row.oid, type: type},
                        });
                        break;
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }

            }
        }

        .send-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;
            min-height: 750px;
            .send-condition {
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;

                .send-condition-item {
                    margin-right: 20px;
                    span {
                        margin-right: 10px;
                    }
                }
                .checked-chiose {
                    margin: 0 20px;
                    span {
                        font-size: 14px;
                    }
                }
                a {
                    color: #999;
                    font-size: 14px;
                }

            }

            .send-list {
                padding: 34px 28px;
                min-height: 600px;

                .dynamic-list {
                    overflow: hidden;

                    .dynamic-item {
                        display: block;
                        padding-left: 25px;
                        position: relative;
                        &:hover {
                            cursor: pointer;
                        }
                        /*&.active {*/
                        /*&::before {*/
                        /*background: #3b77e7;*/
                        /*}*/
                        /*}*/
                        /*&::before {*/
                        /*position: absolute;*/
                        /*top: 10px;*/
                        /*left: 5px;*/
                        /*content: '';*/
                        /*width: 10px;*/
                        /*height: 10px;*/
                        /*border-radius: 100%;*/
                        /*background: #dddddd;*/
                        /*z-index: 3;*/
                        /*}*/

                        /*&::after {*/
                        /*position: absolute;*/
                        /*top: 15px;*/
                        /*left: 10px;*/
                        /*content: '';*/
                        /*width: 1px;*/
                        /*height: 85px;*/
                        /*background: #f4f4f4;*/
                        /*}*/
                        &:last-child {
                            &::after {
                                display: none;
                            }
                        }
                        span {
                            font-size: 16px;
                            line-height: 30px;
                            color: #999999;
                        }

                        p {
                            font-size: 18px;
                            line-height: 30px;
                            height: 60px;
                            color: #333333;

                            b {
                                color: #3b77e7;
                                font-weight: normal;
                            }
                        }
                    }
                }
                .page-all {
                    text-align: center;
                    padding: 30px 0 0;
                }
            }
        }
    }

</style>